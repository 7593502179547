import React from "react";

import { useGetUsersQuery, useCreateInvitationMutation } from "../../store/services/landing/landing.service";

const Landing = () => {
	const { data, error } = useGetUsersQuery();
	const [invite, { isLoading }] = useCreateInvitationMutation();
	console.log(data, error, isLoading);

	const handleClick = () => {
		invite({ title: "test", body: "test", userId: 1 });
	};

	return (
		<div onClick={handleClick}>
			<div>{data?.map(user => <p key={user._id}>{user.name}</p>)}</div>
		</div>
	);
};

export default Landing;
