import { api } from "../index";

export const landingAPI = api.injectEndpoints({
	endpoints: build => ({
		getUsers: build.query<{ _id: number; name: string }[], void>({
			query: () => ({ url: "/users", method: "GET" })
		}),
		createInvitation: build.mutation<
			{ body: string; id: number; title: string; userId: number },
			{ title: string; body: string; userId: number }
		>({
			query: body => ({
				url: `/posts`,
				method: "POST",
				body
			})
		}) as any
	})
});

export const { useGetUsersQuery, useCreateInvitationMutation } = landingAPI;
